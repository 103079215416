/* HeaterTubeSimulation.css */

.HeaterTubeSimulation {
  font-family: Arial, sans-serif;
  max-width: 1000px;
  margin: 30px auto;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.HeaterTubeSimulation:hover {
  transform: scale(1.01);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

h1 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

h2 {
  color: #555;
  border-bottom: 2px solid #007bff;
  padding-bottom: 10px;
  margin-bottom: 15px;
  font-size: 1.2rem;
}

.input-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 20px; /* Added gap between sections */
}

section {
  flex: 1;
  min-width: 250px; /* Minimum width for smaller screens */
  margin: 0; /* Reset margin */
  padding: 15px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s;
}

section:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}

label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #444;
}

input[type="number"] {
  width: 90%; /* Full width for better accessibility */
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align:center;
  font-size: 1rem;
  transition: border-color 0.3s, box-shadow 0.3s;
}

input[type="number"]:focus {
  border-color: #007bff;
  outline: none;
  text-align:center;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.get-data-button{
	background-color: #cc0000; /* dark red background */
    border: none; /* Removes default border */
    padding: 10px 15px; /* Adds some padding */
    border-radius: 5px; /* Rounds the corners */
    cursor: pointer; /* Changes the cursor on hover */
    font-size: 16px; /* Adjusts font size */
}

.get-data-button:hover {
    background-color: #990000; /* Darker red on hover */
}

.bcontainer {
    display: flex;
    align-items: center; /* Aligns items vertically in the center */
}

.bcontainer p {
    margin-right: 10px; /* Adds some space between the paragraph and the button */
}

.btn {
  display: block;
  width: 100%;
  padding: 15px;
  margin-top: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.btn:hover {
  background-color: #0056b3;
  transform: scale(1.02);
}

.results-container {
  margin-top: 20px;
  padding: 15px;
  background-color: #e9ecef;
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.results-columns {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.mole-fractions, .volumetric-flow-rate {
  flex: 1; /* Equal width for both columns */
  min-width: 300px; /* Prevents columns from getting too narrow */
  margin: 10px; /* Adds space between columns */
}

h3 {
  color: #007bff; /* Optional: Color for headings */
  margin-bottom: 10px;
}


@media (max-width: 768px){
	.input-container{
		flex-direction:column;
	}
	section{
		margin: 10px 0;
	}
	.bcontainer{
		font-size:10px;
	}
	.get-data-button{
		font-size:10px;
	}
}