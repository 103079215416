/* Contact.css */

.container {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    flex-wrap: wrap;
    gap: 20px; /* Gap between columns */
  }
  
  .column {
    width: 30%; /* Default width for desktop */
    text-align: center;
  }
  
  .image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .button {
    background-color: #28A745;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }
  
  .contact-section {
    text-align: center;
    padding-top: 20px;
  }
  
  /* Mobile styles */
  @media (max-width: 600px) {
    .container {
      flex-direction: column;
      align-items: center;
    }
  
    .column {
      width: 100%; /* Full width for mobile */
      max-width: none; /* Remove max-width restriction */
    }
  }
  